import React from 'react';

const Navbar = () => (
  <nav className="bg-white shadow-lg p-4 flex justify-between items-center">
    <div className="text-4xl font-display font-bold">Meeka</div>
    <div className="flex space-x-4">
      <a href="#home" className="text-xl text-gray-900 hover:text-black font-body">Home</a>
      <a href="#about" className="text-xl text-gray-900 hover:text-black font-body">About</a>
      <a href="#memes" className="text-xl text-gray-900 hover:text-black font-body">Memes</a>
      <a href="#contact" className="text-xl text-gray-900 hover:text-black font-body">Contact</a>
      <a href="https://pump.fun" className="bg-green-800 text-xl text-white px-4 py-2 rounded-full hover:bg-purple-700 font-body">Buy Now</a>
    </div>
  </nav>
);

export default Navbar;
