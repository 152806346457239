import React from 'react';
import { motion } from 'framer-motion';
import './Divider.css';

const logos = [
  '/stickers/logo.webp',
  '/stickers/wot.webp',
];

const Divider = () => {
  return (
    <div className="logos">
      <motion.div
        className="logos-slide"
        animate={{ x: ['0%', '-100%'] }}
        transition={{
          repeat: Infinity,
          repeatType: 'loop',
          duration: 40,
          ease: 'linear',
        }}
      >
        {Array.from({ length: 40 }, (_, i) => (
          <img 
            key={i} 
            src={logos[i % 2]} 
            alt={`Logo ${i % 2 === 0 ? 'logo' : 'wot'}`} 
            className={`logo ${i % 2 === 0 ? 'logo-even' : 'logo-odd'}`}
          />
        ))}
      </motion.div>
    </div>
  );
};

export default Divider;