import React from 'react';

const About = () => (
  <section id="about" className="bg-white p-8 text-center">
    <h2 className="text-2xl md:text-3xl font-display font-bold mb-4">Meet Meeka</h2>
    <div className="w-full md:w-4/5 lg:w-3/5 mx-auto">
      <div className="mb-4">
        <img 
          src="/vacation.jpg" 
          alt="Meeka on vacation" 
          width="640" 
          height="360" 
          className="mx-auto"
        />
      </div>
      <p className="text-base md:text-xl lg:text-3xl font-body mb-4">
        Meeka, our delightful Samoyed, was found wandering the snowy streets one winter night, her bright eyes full of curiosity. A kind-hearted stranger rescued her, bringing her to a warm home where she quickly became the heart of the household with her playful spirit and love for snuggling. Inspired by her resilience and the joy she brings to everyone she meets, we created Meeka to share her story and spread happiness even further. Now, Meeka is ready to embark on a new adventure on the blockchain, bringing all her personality to Solana.
      </p>
    </div>
  </section>
);

export default About;
