import React, { useEffect, useRef, useState } from 'react';
import Matter from 'matter-js';

const SpinningImage = () => {
  const scene = useRef(null);
  const engineRef = useRef(null);
  const renderRef = useRef(null);
  const bodiesRef = useRef([]);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const imageRefs = useRef([]);
  const images = ['/meeka.png', '/meeka.png', '/meeka.png', '/meeka.png', '/meeka.png', '/meeka.png','/meeka.png', '/meeka.png', '/meeka.png', '/meeka.png', '/meeka.png', '/meeka.png','/meeka.png', '/meeka.png', '/meeka.png'];

  // Define maximum speed constant
  const MAX_SPEED = 10;

  useEffect(() => {
    const loadImages = async () => {
      const loadedImages = await Promise.all(
        images.map((src) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = src;
          });
        })
      );
      imageRefs.current = loadedImages;
      setImagesLoaded(true);
    };

    loadImages();
  }, []);

  useEffect(() => {
    if (!imagesLoaded) return;

    const { Engine, Render, World, Bodies, Body, Runner, Events, Vector } = Matter;

    const width = window.innerWidth;
    const height = window.innerHeight;

    const engine = Engine.create({
      gravity: { x: 0, y: 0 }
    });
    engineRef.current = engine;

    const render = Render.create({
      element: scene.current,
      engine: engine,
      options: {
        width,
        height,
        wireframes: false,
        background: 'white',
      },
    });
    renderRef.current = render;

    const dogRadius = 72;

    // Function to cap speed
    const capSpeed = (body) => {
      const velocity = body.velocity;
      const speed = Vector.magnitude(velocity);
      if (speed > MAX_SPEED) {
        const scaleFactor = MAX_SPEED / speed;
        Body.setVelocity(body, Vector.mult(velocity, scaleFactor));
      }
    };

    const bodies = imageRefs.current.map((img) => {
      const x = Math.random() * width;
      const y = Math.random() * height;
      const body = Bodies.circle(x, y, dogRadius, {
        restitution: 0.8,
        friction: 0,
        frictionAir: 0,
        inertia: Infinity,
      });
      const speed = Math.min(8, MAX_SPEED);
      const angle = Math.random() * Math.PI * 2;
      Body.setVelocity(body, { 
        x: Math.cos(angle) * speed, 
        y: Math.sin(angle) * speed 
      });
      Body.setAngularVelocity(body, (Math.random() - 0.5) * 0.8);
      return body;
    });
    World.add(engine.world, bodies);
    bodiesRef.current = bodies;

    const wallThickness = 50;
    World.add(engine.world, [
      Bodies.rectangle(width/2, -wallThickness/2, width, wallThickness, { isStatic: true }),
      Bodies.rectangle(width/2, height + wallThickness/2, width, wallThickness, { isStatic: true }),
      Bodies.rectangle(-wallThickness/2, height/2, wallThickness, height, { isStatic: true }),
      Bodies.rectangle(width + wallThickness/2, height/2, wallThickness, height, { isStatic: true }),
    ]);

    Events.on(engine, 'collisionStart', (event) => {
      const pairs = event.pairs;
      for (let i = 0; i < pairs.length; i++) {
        const { bodyA, bodyB } = pairs[i];
        const speedMultiplier = 1.05;
        Body.setVelocity(bodyA, { 
          x: bodyA.velocity.x * speedMultiplier, 
          y: bodyA.velocity.y * speedMultiplier 
        });
        Body.setVelocity(bodyB, { 
          x: bodyB.velocity.x * speedMultiplier, 
          y: bodyB.velocity.y * speedMultiplier 
        });
        Body.setAngularVelocity(bodyA, bodyA.angularVelocity * 1.1);
        Body.setAngularVelocity(bodyB, bodyB.angularVelocity * 1.1);
        
        // Cap speed after collision
        capSpeed(bodyA);
        capSpeed(bodyB);
      }
    });

    const runner = Runner.create();
    Runner.run(runner, engine);

    const customRender = () => {
      const context = render.canvas.getContext('2d');

      context.fillStyle = 'white';
      context.fillRect(0, 0, width, height);

      bodies.forEach((body, index) => {
        const { x, y } = body.position;
        const angle = body.angle;

        const wrappedX = (x + width) % width;
        const wrappedY = (y + height) % height;

        context.save();
        context.translate(wrappedX, wrappedY);
        context.rotate(angle);
        context.drawImage(imageRefs.current[index], -dogRadius, -dogRadius, dogRadius * 2, dogRadius * 2);
        context.restore();

        if (x < 0) drawDuplicate(x + width, y);
        if (x > width) drawDuplicate(x - width, y);
        if (y < 0) drawDuplicate(x, y + height);
        if (y > height) drawDuplicate(x, y - height);

        function drawDuplicate(x, y) {
          context.save();
          context.translate(x, y);
          context.rotate(angle);
          context.drawImage(imageRefs.current[index], -dogRadius, -dogRadius, dogRadius * 2, dogRadius * 2);
          context.restore();
        }

        // Cap speed for each body on every frame
        capSpeed(body);
      });

      requestAnimationFrame(customRender);
    };

    const animationId = requestAnimationFrame(customRender);

    return () => {
      cancelAnimationFrame(animationId);
      Render.stop(render);
      Runner.stop(runner);
      World.clear(engine.world);
      Engine.clear(engine);
      if (render.canvas) {
        render.canvas.remove();
      }
    };
  }, [imagesLoaded]);

  return (
    <div ref={scene} className="relative w-screen h-screen">
      {!imagesLoaded && <div>Loading...</div>}
    </div>
  );
};

export default SpinningImage;