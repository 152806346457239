import React, { useState } from 'react';

const Hero = () => {
  const [copied, setCopied] = useState(false);

  const contractAddress = "Gh3YaHLPPi6CLHwGR5C6VHiNjwmdoU3gVT6WCmMCpump";

  const handleCopy = () => {
    navigator.clipboard.writeText(contractAddress);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <section id="home" className="relative bg-white text-gray-800 p-8 text-center overflow-hidden">
      <img 
        src="/Meeka.jpg" 
        alt="Meeka" 
        className="mx-auto rounded-lg shadow-lg mb-4 w-3/4 sm:w-2/3 md:w-1/2 max-w-xs md:max-w-md border-4 border-black z-10" 
      />
      <h1 className="text-4xl sm:text-5xl font-display font-light mb-3 text-black-700 relative z-10">$Meeka</h1>
      <p className="text-xl sm:text-2xl font-body text-black relative z-10">The whitest pup on solana</p>
      <div className="flex flex-wrap justify-center mt-8 space-x-2 sm:space-x-4 relative z-10">
        <a href="https://dexscreener.com/solana/ehy3r78uvykfhdhif9mtpfjerdztdisg7f92mslrqa4f" target="_blank" rel="noopener noreferrer">
          <img src="/buttons/dexscreener.png" alt="Dexscreener" className="h-10 sm:h-12 rounded-lg" />
        </a>
        <a href="https://pump.fun/Gh3YaHLPPi6CLHwGR5C6VHiNjwmdoU3gVT6WCmMCpump" target="_blank" rel="noopener noreferrer">
          <img src="/buttons/pumpfun.webp" alt="Raydium" className="h-10 sm:h-12 border-4 border-black rounded-lg" />
        </a>
        <a href="https://t.me/meekaportal" target="_blank" rel="noopener noreferrer">
          <img src="/buttons/tg.svg" alt="Telegram" className="h-10 sm:h-12 rounded-lg" />
        </a>
        <a href="https://x.com/meeka_sol" target="_blank" rel="noopener noreferrer">
          <img src="/buttons/tw.svg" alt="Twitter" className="h-10 sm:h-12 border-4 border-black rounded-lg" />
        </a>
      </div>
      <div className="mt-8 relative z-10">
        <div 
          className="inline-block px-4 py-2 bg-gray-200 rounded-lg cursor-pointer border-4 border-black"
          onClick={handleCopy}
          style={{ cursor: 'pointer', transition: 'background-color 0.3s' }}
        >
          <span className="text-2xl sm:text-3xl font-bold text-black">
            {copied ? 'Copied!' : 'CA: ' + contractAddress}
          </span>
        </div>
      </div>
    </section>
  );
};

export default Hero;
